import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import '../App.css';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(var(--vh, 1vh) * 100);

    background-color: #f0f4f8;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 20px;
    border: 2px solid #3987db;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`

const Title = styled.div`
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 1.3rem;
    color: #333;

    margin-right: 10px;
`;

const Spinner = styled.div`
    width: 17px;
    height: 17px;
    border: 8px solid rgba(255, 255, 255, 0.8);
    border-top: 8px solid #348ddb;
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;
`;

function RedirectPage(props) {

    const setScreenHeight = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    useEffect(() => {
        setScreenHeight();
        window.location.href = process.env.REACT_APP_NOTION_LINK;
    }, []);
    
    return (
        <Container>
            <LoadingContainer>
                <Title>사현진's 페이지로 이동 중...</Title>
                <Spinner />
            </LoadingContainer>
        </Container>
    );
}

export default RedirectPage;